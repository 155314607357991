.App {
  background-color: #f1f1ef;
  color: #1A2309;
}

a {
  background-color: white;
}
a:link {
  color: rgb(83, 34, 219);
}

a:visited {
  color: rgb(148, 49, 161);
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}