h1 {
    font-family: "Source Sans Pro","Helvetica Neue",Arial,sans-serif;
    font-weight: 900;
    font-size: 52px;
}

.pizza-container {
    max-width: 728px;
    padding: 0 10px 10px 10px;
}

.iframe-container-16-9 {
    overflow: hidden;
    position: relative;
}

@media only screen and (max-width: 500px) {
    .iframe-container-16-9 {
        padding-top: 56.25%;
        width: 100%;
    }
}

@media only screen and (min-width: 500px) {
    .iframe-container-16-9 {
        width: 560px;
        height: 315px;
    }
}

.iframe-container-16-9 iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}